@page
{
	/*margin: 1cm;*/
}

@media print
{
	#print
	{
		display: none;
	}
}
#print
{
	border: none;
    width: 50px;
    height: 50px;
    background-color: #a5a5a5;
    border-radius: 100%;
    position: fixed;
    top: 5%;
    left: 90%;
    transform: translate(-50%, -50%);
}
html {
	font-size: 62.5%;
}

body {
	font-family: "Rubik", sans-serif;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
}

.main-div_pdf {
	max-width: 700px;
	margin: 0 auto;
	height: auto
	
}

.cv_header {
	background-color: #e7f9f8;
	display: flex;
	padding: 1.5rem 3rem;
	align-items: stretch;
	background-color: #BCCE98;
}

.cv_image {
	width: 7rem;
	height: 7rem;
}
.cv_image img {
	width: inherit;
	height: inherit;
	border-radius: 50%;
}

.cv_basicDetails {
	margin-left: 2.5rem;
	font-family: Rubik;
	color: #000000;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.cv_basicDetails-name {
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 0;
}
.cv_basicDetails-qualification {
	margin: 0px;
	font-size: 1.2rem;
	margin-bottom: 0;
}
.cv_basicDetails-contact {
	display: flex;
	width: 100%;
	justify-content: space-between;
	font-size: 1.1rem;
}
.cv_basicDetails-contact > span {
	display: flex;
	align-items: center;
}
.cv_basicDetails-contact > span svg {
	margin-right: 0.7rem;
}
.cv_basicDetails-contact > span span {
	margin-top: 0.3rem;
}

.cv_mainContent {
	width: 100%;
	/*display: flex*/
}
.cv_mainContent-innerWrapper {
	margin-top: 2rem;


}
.cv_mainContent-innerWrapper > div{
	margin-left: 2.5rem;
	/*page-break-inside: avoid;*/
}
.cv_mainContent-heading {
	font-size: 1.6rem;
}
.cv_mainContent-heading-details {
	margin-top: 2.3rem;
}
.cv_mainContent-collegeDetails {
	font-size: 1.37rem;
	color: #4a4a4a;
}
.cv_mainContent-work-details {
	margin-top: 2rem;
}
.cv_mainContent-workDescription {
	font-size: 1.37rem;
	color: #4a4a4a;
}
.cv_mainContent-footer {
	display: flex;
	align-items: center;
}
.cv_mainContent-footer span {
	font-size: 1.2rem;
	color: #4a4a4a;
	display: inline-block;
	margin-top: 1rem;
	margin-right: 0.5rem;
}
.cv_mainContent-footer img {
	height: 2.3rem;
}

.cv_mainContent-skillsList {
	padding: 0px 2.5rem 0px 2.5rem;
	margin-top: 2.27rem;
	-webkit-columns: 2;
	-moz-columns: 2;
	columns: 2;
	-webkit-column-gap: 30%;
	-moz-column-gap: 30%;
	column-gap: 30%;
	-moz-column-fill: balance;
	column-fill: balance;

}
.cv_mainContent-skills {
	margin-right: 1rem;
	font-size: 1.2rem;
	color: #4a4a4a;
	margin-bottom: 1rem;
}
.cv_mainContent-languageSkills {
	margin-top: 1rem;
}
.cv_mainContent-languageSkills:first-of-type {
	margin-top: 2.27rem;
}
.cv_mainContent-language {
	width: 5rem;
	display: inline-block;
	color: #4a4a4a;
	font-size: 1.2rem;
}
.cv_mainContent-languageProficiency {
	margin-left: 1rem;
	color: #d8d8d8;
	font-size: 1.2rem;
	font-weight: 500;
}
.cv_mainContent-languageProficiency span {
	color: #007e76;
	font-size: 1rem;
}
.key
{
	color: #000000;
}
.cv_mainContent-education-details
{
	margin-top: 2rem;
}
.cv_mainContent-extra-curr
{
	margin-left: 2.5rem;
}
#head_shot_holder
{
	font-family: rubik;
	position: relative;
	width: 7rem;
	height: 7rem;
	overflow: hidden;
	display: table-cell;
}
#head_shot
{
	width: inherit;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100%;
}
#degree
{
	margin: 8px 0px 5px 0px;
	margin: 0px;
	font-size: 1.2rem;
	margin-bottom: 0;
	font-weight: 400;
}
#name
{
	font-size: 2.5rem;
	font-weight: 500;
	margin-bottom: .5rem;
	text-align: center;
}
#basic_info
{
	/*  display: table-cell;*/
	width: 100%;
	text-align: center;
}
#contact
{
	padding: 0px;
	margin: 0px;
}
#contact li
{
	display: inline-block;
	margin-right: 24px;
	font-size: 1.1rem;
	margin-top: 0.3rem;
	margin: auto;
	padding: 10px 20px 0px 20px;
}
.list_image
{
	margin-right: 5px;
}
header
{
	padding: 1.5rem 3rem;
	border-bottom: 2px solid #007e76;
	/*background-color: #e7f9f8*/
}
p
{
	orphans: 3;
	widows: 3;
	column-count: 1;
	overflow-wrap: break-word;
}     
.values
{
	color: #007e76;
}
.break_avoid_inside
{
	page-break-inside: avoid;
}
.break_force_before
{
	page-break-before: always;
}
.break
{
	page-break-before: always;
	/*margin-bottom: .5cm;*/
	border-bottom: 2px solid #007e76;
	height: 1cm;
}
.page_footer
{
	/*margin-left: 25px;*/
	font-size: 1.2rem;
}
.flipr_logo
{
	margin-left: 15px;
	width: 52px;
}
.border
{
	height: 2px;
	width: 100%;
	background-color: #007e76;
}