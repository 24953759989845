.navbar {
  padding: 1.4rem 0rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.08);
  z-index: 10000;
  background-color: white !important;
}

.navbar .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar .narrow {
  width: 90%;
}

.narrow {
  width: 90%;
  margin: 0 auto;
}

.logo-vert {
  display: flex;
  align-items: center;
}

.nav-logo {
  height: 50px;
}

.navbar-brand-text {
  font-size: 2rem;
  font-weight: 300;
  height: 100%;
}
@media screen and (max-width: 576px) {
  .navbar-brand-text {
    margin-left: -5rem;
    display: none;
  }
}

.navbar .list {
  display: flex;
  flex-direction: row;
}

.navbar .user-image {
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  /* border: 1px solid #ccc; */
  overflow: hidden;
}

.navbar .vertical-line {
  margin: auto 2rem;
  height: 3rem;
  margin-top: 0.5rem;
  border: 1px solid #999999;
}

.navbar .user-img-tag {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover
}
