.profile-comp {
  height: 9rem;
  background-color: #f7f7f7;
  margin-top: 5.4rem;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 500;
  font-size: 18px;
  /* position: fixed; */
  /* width:100vw; */
  /* z-index: 5000; */
}
@media screen and (max-width: 991px) {
  .profile-comp {
    height: 20rem;
  }
}

.profile-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
@media screen and (max-width: 991px) {
  .profile-align {
    flex-direction: column;
  }
}

.content {
  display: flex;
}
@media only screen and (max-width: 991px) {
  .content {
    flex-direction: column;
  }
}


.progress{
  border: 1px solid #00b6aa !important;
  width:40%;
  height:18px;
}

.progress-bar{
  background-color: #00b6aa;
  font-size: 15px;
}

/* css for date picker */
.cXcRCo{
  box-shadow: 2px 2px 2px 2px #ccc;
  z-index: 1000
}
.jgwYAS{
  z-index: 10000;
}

.btn-trans-findjobs{
  padding: 1rem 0rem !important;
  width: 9rem !important
}

