.formdiv {
  flex: 1;
  min-height: 125rem;
  padding: 5rem 5rem 5rem 3.2rem;
  color: black;
}

.formdiv .profile-info-header {
  height: 6rem;
  background-color: #d0f4f1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 3.5%;
  padding-right: 5%;
  font-size: 1.6rem;
  margin-left: 2rem;
  margin-bottom: 5rem;
}
/* .formdiv .form-start {
  margin: 2rem;
} */
.formdiv .form-group {
  padding: 1.5rem 10% 1.5rem 5%;
}

.formdiv .error {
  color: red;
  font-size: 12px;
  padding: 5px;
}


/* for textPath */
.customtextarea {
  border: 1.5px solid #00b6aa;
  margin-top: 10px;
  font-size: 16px;
  color: #4a4a4a  
}
.customtextarea:focus {
  border: 1.5px solid #00b6aa;
}

.formdiv .custom_btn_div {
  margin: 7rem 7rem 25rem;
  display: flex;
  justify-content: space-between;
}

.formdiv .topborder {
  border-top: 1px solid rgba(0, 182, 170, 0.26);
  width: 90%;
  margin: 3rem auto;
}

.formdiv .search_bar_w {
  width: 30% !important;
}

.formdiv .error-op {
  opacity: 0;
}

.btn-arrow-for {
  padding-bottom: 5px;
  /* padding-top: 5px; */
  font-size: 20px;
}

.btn-arrow-back {
  padding-bottom: 10px;
  font-size: 20px;
}
